import React, { useEffect, useRef, useState } from 'react'
import il_ilce from '../Header/data.json'
import Select from 'react-select'
import './index.css'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Button } from '@mui/material';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAdverts } from '../../Redux/Homepage'
import { MdKeyboardArrowDown } from "react-icons/md";
import { Alert } from '../../Helpers/helpers';
import Popup from '../../Components/Popup';


export const Sidebar = () => {
  const Title = useRef();
  const [filterPopup, setFilterPopup] = useState(false);
  const fontColor = localStorage.theme == 'dark' ? 'white': 'black'
  const [ilceler, setIlceler] = useState([]);
  const [isAward, setIsAward] = useState(false);
  const [il, setIl] = useState("Şehir");
  const [ilce, setIlce] = useState("İlçe");

  const dispatch = useDispatch();
  const { Adverts } = useSelector((store) => store.home);


  // CUSTOM FUNCS
  const selectCity = (e) => {
    let cityValue = e.value;
    setIl(cityValue);
    for(var i = 0; i < il_ilce.length; i++){
        if(cityValue == il_ilce[i].value){
            setIlceler(il_ilce[i].ilceler)
        }
    }
    let ilInp = document.querySelector("[name='il']");
    if(ilInp != undefined && ilInp != null){
      ilInp.value = cityValue;
    }

  }
  const selectDistrict = (e) => {
    let district = e.value;
    setIlce(district);
    let ilInp = document.querySelector("[name='ilce']");
    if(ilInp != undefined && ilInp != null){
      ilInp.value = district;
    }

  }


  const filteradverts = async () => {
    let Url = process.env.REACT_APP_API_URL +  '/adverts';
    Url = `${Url}?title=${Title.current.value}&city=${il}&district=${ilce}&is_award=${isAward}`;
    
    await axios.get(Url).then((response) => {
      let cevap = response.data;
      if(cevap.status == "success"){
        dispatch(setAdverts(cevap.data));
      }else{
        alert(cevap.message);
      }
    }).catch((error) => {

    })
  }
  const toggleFilter = () => {
    document.getElementById('side-menu').classList.toggle('hidden');
    document.getElementById('filterArrow').classList.toggle('rotate-180')
  }

  const createPost = () => {
    if(localStorage.token != undefined && localStorage.token != null){
      window.location.replace('/create');
    }else{
      Alert({
        type: "info",
        title: "Giriş yapın",
        text: "Lütfen Önce Giriş yapınız"
      })
      return false;
    }
  }


  return (
    <div className='w-full flex flex-row lg:flex-col justify-between lg:justify-start pb-2 lg:w-1/5 lg:fixed z-50'>
        {/* <Button onClick={() => {createPost()}} type='button' variant='contained' color='warning'>İlan paylaş</Button>
        
        <Button variant='outlined' className='text text-md cursor-pointer font-bold flex items-center mt-3 gap-2' onClick={( () => { setFilterPopup(true) } )} 
        style={{ color:fontColor }}>Filtrele</Button> */}

        <Popup open={filterPopup} Close={() => setFilterPopup(false)}>
          <h1 className='text text-md font-bold'>İlanları Filtrele</h1>
          <div id="side-menu" className='side-menu lg:p-0 flex flex-col justify-center gap-4'>
              <div className='side-item'>
                  <input placeholder='Ad Soyad' ref={Title} type='text' id='human_name'
                  className='p-2 w-full border border-gray rounded-md bg-transparent' />
              </div>
              <div className='side-item'>
                  <Select options={il_ilce} id='filter_il'
                      className='w-full bg-transparent' placeholder={il}
                      value={il} defaultValue={il} label={il} styles={{ width: "100%", color:fontColor }}
                      onChange={(e) => { selectCity(e) }} />
              </div>
              <div className='side-item'>
                <Select options={ilceler} label={ilce} styles={{ width: "100%" }}
                    value={ilce} defaultValue={ilce} id='filter_ilce'
                    onChange={(e) => { selectDistrict(e) }}
                    className='w-full' placeholder={ilce} />
              </div>
              <div className="slide-item">
                <div className="form-group">
                  <FormControlLabel control={<Switch 
                  onChange={() => { setIsAward(!isAward) }} />} 
                 labelPlacement="start" color={fontColor} label="Ödüllü" />
                </div>
              </div>
              <div className='slide-item justify-between flex flex-row gap-2'>
                <Button variant='contained' onClick={() => { filteradverts() }} className='slide-item' color='primary'>Filtrele</Button>
                <Button variant='contained' onClick={() => { window.location.reload() }} className='slide-item' color='warning'>Temizle</Button>
              </div>
          </div>
        </Popup>
    </div>
  )
}
