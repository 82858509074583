import * as yup from 'yup';
const passwordMatches = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/

export const register = yup.object().shape({
    name: yup.string().required('Ad Soyad zorunludur'),
    email: yup.string().email('E-posta formatı girmelisiniz.').required('Email zorunludur'),
    password: yup.string().required('Şifre zorunludur').min(8,'Şifre en az 8 haneli olmalı.'),
    /*
    .matches(passwordMatches,
         { message: "Şifrede en az 1 Büyük harf, 1 Küçük harf ve 1 adet özel karakter olamalıdır."}),
         */
         password_confirmation: yup.string().required('Şifre Tekrarı zorunludur')
    .min(8,'Şifre en az 8 haneli olmalı.').oneOf([yup.ref('password'), null], 'Şifreler eşleşmiyor.')

})