import Swal from 'sweetalert2'


export const Alert = ({ type, expectValue=false,data="", param=false, title, text, input=false, cancelButtonText=null, confirmButtonText=null, successFunc=()=>{}, cancelFunc=()=>{} }) => {
    return Swal.fire({
        type: type ?? "error",
        title: title ?? "Hata",
        text: text ?? "Alert mesajı boş",
        icon: type ?? "error",
        showCancelButton: true,
        cancelButtonText: cancelButtonText ?? "İptal",
        confirmButtonText: confirmButtonText ?? 'Tamam',
        input: input,
    }).then((response) => {
        console.log(response)
        if (response.isConfirmed) {
            if(expectValue && param){
                data[param] = response.value
            }
            if(!response.isDismissed){
                return successFunc(data);
            }
        } else {
            return cancelFunc(response);
        }
      })
}

export const checkAuth = () => {
    if(localStorage.token == null || localStorage.token == undefined){
        window.location.replace('/');
    }
}

export const customURLEncode = (str) => {
    // Tüm harfleri küçük harfe çevir
    let encodedStr = str.toLowerCase();

    // Boşlukları tire (-) ile değiştir
    encodedStr = encodedStr.replace(/\s+/g, '-');

    // Geçersiz karakterleri çıkart (yalnızca harfler, sayılar ve tire (-) kalacak)
    encodedStr = encodedStr.replace(/[^a-z0-9\-]/g, '');

    return encodedStr;
}

export const dateConvert = (date) => {
    let format = new Date(date);
    let year   = format.getFullYear();
    let month  = format.getMonth();
    month = String(month).length == 1 ? "0" + month : month;
    let day    = format.getDay();
    day = String(day).length == 1 ? "0" + day : day;
    let hour   = format.getUTCHours();
    hour = String(hour).length == 1 ? "0" + hour : hour;
    let minute = format.getUTCMinutes();
    minute = String(minute).length == 1 ? "0" + minute : minute;

    return `${day}/${month}/${year} ${hour}:${minute}`;
}