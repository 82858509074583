import PostCard from '../../Components/PostCard';
import { useDispatch, useSelector } from 'react-redux';
import { setAdverts } from '../../Redux/Homepage'
import axios from 'axios';
import Popup from '../../Components/Popup';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { FaStar } from 'react-icons/fa';
import { Alert, checkAuth }  from '../../Helpers/helpers';
import { register } from '../../Validataions/Register';


const Profile = () => {
  const fontColor = localStorage.theme == 'dark' ? 'white' : 'black';
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const dispatch = useDispatch();
  const { Adverts } = useSelector((store) => store.home);
  const { Me } = useSelector((store) => store.me);
  const header = {
    "Authorization" : "Bearer " + localStorage.token
  }


  const getAdverts = async () => {
    await axios.get( process.env.REACT_APP_API_URL +  '/profile', { headers: header }).then((response) => {
      let cevap = response.data;
      if(cevap.status == "success"){
        dispatch(setAdverts(cevap.data.adverts));
      }else{
        alert(cevap.message);
      }
    }).catch((error) => {
      alert(error.response.message);

    })
  }


  useEffect(() => {
    checkAuth();
    getAdverts();
  },[])

  return (
    <div className='flex p-10 flex-col gap-2'>


        <span className='text text-md font-bold' style={{ color:fontColor }}
        >{ Me?.name } / { Me?.email } </span>

        <div className='ilanlar mt-2'>
            <h1 className='informations text font-bold text-2xl text-center' style={{ color:fontColor }}>İlanlar</h1>
            <Popup open={openModal} Close={() => { setOpenModal(false) }}>
                <h1 className='flex items-center gap-2 text-lg font-bold text-gold pb-2'
                ><FaStar className='text-gold cursor-pointer' /> Ödüllü</h1>
                    { modalContent }
            </Popup>
            <div className='posts mt-4 grid grid-cols-6 justify-center gap-4 w-full'>
            {
            Adverts.map(
            item => <PostCard getAdverts={() => { getAdverts() }}
              className={"col-span-6 md:col-span-3 lg:col-span-2 w-full"} style={{ width:"100%" }}
            own={true} data={item} awardModal={(award) => {setOpenModal(true); setModalContent(award) }}
             />
            )}
            </div>
        </div>

        

    </div>
  )
}

export default Profile