import { Formik, useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import il_ilce from './data.json'
import Select from 'react-select'
import './index.css'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {createPost} from '../../Validataions/CreatePost';
import { Alert } from '../../Helpers/helpers';
import axios from 'axios';
import Swal from 'sweetalert2';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const CreatePost = () => {
    const [type, setType] = useState("human");
    var [images, setImages] = useState("");
    const [issetAward, setIssetAward] = useState(false);
    const [ilceler, setIlceler] = useState([]);
    const [il, setIl] = useState();
    const fontColor = localStorage.theme == 'dark' ? 'white' : 'black';

  // CUSTOM FUNCS
  const selectCity = (event) => {
    event.preventDefault();
    let cityValue = event.target.value;
    setIl(cityValue);
    for(var i = 0; i < il_ilce.length; i++){
        if(cityValue == il_ilce[i].value){
            setIlceler(il_ilce[i].ilceler)
        }
    }
    let ilInp = document.querySelector("[name='il']");
    if(ilInp != undefined && ilInp != null){
      ilInp.value = cityValue;
    }

  }
  const selectDistrict = (event) => {
    let district = event.value;
    let ilInp = document.querySelector("[name='ilce']");
    if(ilInp != undefined && ilInp != null){
      ilInp.value = district;
    }

  }
 

  
const readURL = (e) => {
  images = e.target.files[0]
  setImages(images)
  var input = document.getElementById("file");
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    reader.onload = function (e) {
      var imgprev = document.createElement("img");
      imgprev.setAttribute('src', e.target.result)
      imgprev.setAttribute('style', "width:120px; height:120px")
      document.getElementById('imageAlani').innerHTML = "";
      document.getElementById('imageAlani').appendChild(imgprev);
    };
    reader.readAsDataURL(input.files[0]);
  }
}

const chooseType = (e, type) => {
  let btns = document.getElementsByClassName('type-btn');
  for(var i = 0; i < btns.length; i++){
    btns[i].classList.remove('choosed');
  }
  e.target.classList.add('choosed');
}

  useEffect(()=>{},[])

  return (
    <div className='card relative flex mx-auto flex-col gap-4'>
      <div className='create-card w-full md:w-1/2 border border-black rounded-xl bg-white dark:bg-dark-component mt-10 mx-auto p-4'>

        <div className='btngroup flex items-center gap-2'>
          <button className={
             type == 'human' ? 
                'type-btn p-2 text-nowrap w-full bg-primary text-white' :
                  'type-btn p-2 text-nowrap w-full bg-gray text-white hover:bg-primary hover:text-white'
              }
            onClick={() => { setType('human') }} >İnsan</button>

          <button className={
             type == 'animal' ? 
                'type-btn p-2 text-nowrap w-full bg-primary text-white' :
                  'type-btn p-2 text-nowrap w-full bg-gray text-white hover:bg-primary hover:text-white'
              }
          onClick={() => { setType('animal') }}>Hayvan</button>

          <button className={
             type == 'item' ? 
                'type-btn p-2 text-nowrap w-full bg-primary text-white' :
                  'type-btn p-2 text-nowrap w-full bg-gray text-white hover:bg-primary hover:text-white'
              }
          onClick={() => { setType('item') }}>Eşya</button>
        </div>

        <Formik
          initialValues={{ 
            title : "", 
            age: "",
            gender: "",
            il: "",
            ilce: "",
            date: "", 
            phone: "", 
            isAward: issetAward,
            award: "#",
            description: ""}}
            validationSchema={createPost}
          onSubmit={(values, { setSubmitting }) => {
            if(type == "human" || type == "animal"){
              if(values.gender == "none" ||images.length <= 0){
                Alert({
                  type: "error",
                  title: "Hata",
                  text: "Lütfen tüm bilgileri eksiksiz giriniz"
                })
                return false;
              }
            }
            let data = { ...values, "image" : images, "type" : type }
            axios.post(process.env.REACT_APP_API_URL + '/create', data, { headers : { 
              'Content-Type': 'multipart/form-data',
              "Authorization" : "Bearer " + localStorage.token 
            } })
            .then((response) => {
              response = response.data;
              if(response.status == "success"){
                Swal.fire({
                  type:"success",
                  title: "Başarılı",
                  text: "İlan başarıyla paylaşıldı.",
                  showCancelButton: true,
                  confirmButtonText: "İlanı gör",
                  cancelButtonText: "Kapat",
                }).then((respo) => {
                  if(respo.isConfirmed){
                    window.location.replace('/ilan/' + response.data.id + "-" + response.data.slug);
                  }
                })
              }else{
                setSubmitting(false)
                Alert({
                  type:"error",
                  title: "Hata",
                  text: response.message
                })
              }
            }).catch((err) => {
              console.log(err)
              setSubmitting(false)
              if(err.response != undefined){
                Alert({
                  type:"error",
                  title: "Hata",
                  text: err.response.data.message
                })
              }else{
                Alert({
                  type:"error",
                  title: "Hata",
                  text: err
                })
              }
            })
          }}
        >
        {({
          values,
          errors,
          setFieldValue,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
        <form className='mt-4' onSubmit={handleSubmit}>
          <div className='grid grid-cols-6 items-center gap-4 flex-wrap'>
              <div className='formgroup col-span-3 flex flex-col justify-start'>
                  <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      style={{ textWrap: "nowrap" }}
                      >
                      Görsel yükle
                      <VisuallyHiddenInput accept='image/*'  required
                      id="file" onChange={(e) => { readURL(e); }} name='file' type="file" />
                  </Button>
              </div>
              <div className='col-span-3' id='imageAlani'>
              </div>


              <div className={type == "item" ? 'form-group col-span-6 flex flex-col justify-start' :
              'form-group col-span-4 flex flex-col justify-start'}>
                  <input required  placeholder='Başlık ( İsim )' value={values.title} onChange={handleChange}
                  type='text' id='title' className='p-2 border border-gray rounded-md' />
                    { errors.title && <span className="text text-md text-danger">{ errors.title }</span>}
              </div>


              {
                type != "item" ? 

                <div className='form-group col-span-2 flex flex-col justify-start'>
                    <input required  placeholder='Yaş' type='number' id='age' onChange={handleChange}
                    value={values.age} className='p-2 border border-gray rounded-md' />
                    { errors.age && <span className="text text-md text-danger">{ errors.age }</span>}
                </div> : <></> 
              }


              {
                type != "item" ? 
                <div className='form-group col-span-2 flex flex-col justify-start'>
                    <select className='p-2 border border-gray rounded-md' required
                    value={values.gender} id="gender" style={{ color: fontColor}} onChange={handleChange}>
                        <option value="none" className='opacity-50'>Cinsiyet</option>
                        <option value="Erkek">Erkek</option>
                        <option value="Kadın">Kadın</option>
                    </select>
                </div> : <></>
              }


              <div className={type == 'item' ? 'form-group col-span-3 flex flex-col justify-start' :
               "form-group col-span-2 flex flex-col justify-start" }>
                  <select il='il'required style={{ color: fontColor}}
                  className='p-2 border border-gray rounded-md' placeholder={il} onChange={(e) => { selectCity(e); setFieldValue('il', e.target.value) }}>
                    <option value={"none"}>İl</option>
                    {
                      il_ilce.map( data => <option value={data.value}>{data.value}</option>)
                    }
                  </select>
              </div>
              


              <div className={type == 'item' ? 'form-group col-span-3 flex flex-col justify-start' :
               "form-group col-span-2 flex flex-col justify-start" }>
                  <select style={{ color: fontColor}}
                    onChange={(e) => { setFieldValue('ilce', e.target.value) }}
                    className='p-2 border border-gray rounded-md'>
                      <option value={"none"}>İlçe</option>
                      {
                        ilceler.map( data => <option value={data.value}>{data.value}</option>)
                      }
                    </select>
              </div>


              <div className='form-group col-span-3 flex flex-col justify-start'>
                <label style={{ color: fontColor }} htmlFor='date'> Kayıp tarihi </label>
                <input required  type='datetime-local' value={values.date} onChange={handleChange}
                className='p-2' placeholder='Kayıp tarihi' name='date' id='date' style={{ color: fontColor }} />
                    { errors.date && <span className="text text-md text-danger">{ errors.date }</span>}
              </div>



              <div className='form-group col-span-3 flex flex-col justify-start'>
                <label style={{ color: fontColor }} htmlFor='date'>İletişim Numarası</label>
                <input required  value={values.phone} onChange={handleChange}
                type='text' name='phone' id='phone' placeholder='541 842 54 32' 
                className='p-2 border border-gray rounded-md' /> 
                    { errors.phone && <span className="text text-md text-danger">{ errors.phone }</span>}
              </div>
              


              <div className="award-peace grid grid-cols-8 col-span-6">
                <div className='form-group col-span-2 items-center flex gap-4'>
                    <label className='cursor-pointer' style={{color:fontColor}} htmlFor='issetAward'>Ödüllü</label>
                    <input  type='checkbox' defaultChecked={issetAward}
                      onChange={(e) => { setIssetAward(!issetAward); setFieldValue('isAward', !issetAward)  }} 
                      name='issetAward' id='issetAward' />
                </div>

                <div className={ issetAward ? 'form-group col-span-6 w-full' : 'hidden' }>
                  <input placeholder='Ödül' required={issetAward} type='text' id='award'
                    onChange={handleChange}
                   className='p-2 w-full border border-gray rounded-md' />
                </div>
              </div>


              <div className='form-group col-span-6 flex flex-col justify-start'>
                  <textarea required 
                    placeholder='Açıklama' value={values.description} onChange={handleChange}
                    className='border border-gray p-2 rounded-md' 
                    id='description' style={{ height:"200px", color: fontColor }}
                  ></textarea>
                    { errors.description && <span className="text text-md text-danger">{ errors.description }</span>}
              </div>

              
          </div>
          <div className='buttons mt-3 flex justify-end'>
            <Button variant='contained' type='submit' disabled={isSubmitting} color='success'>Paylaş</Button>
          </div>
        </form>
               )}
        </Formik>
      </div>
    </div>
  )
}
