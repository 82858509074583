import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 390,
  bgcolor: localStorage.theme == 'dark' ? '#1a1a24' : "white",
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

export default function Popup(props) {

  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={props.Close}
        closeAfterTransition
        className='rounded-xl modal'
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        style={{ zIndex:"99999" }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            { props.children }
          </Box>
        </Fade>
      </Modal>
  );
}