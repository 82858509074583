import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    Me: {},
    Founded: []
}

const MeSlice = createSlice({
    name: "me",
    initialState,
    reducers:{
        setMe(state,action){
            state.Me = action.payload;
        },
        setFounded(state,action){
            state.Founded = action.payload;
        }
    }
})

export const { setMe, setFounded } = MeSlice.actions
export default MeSlice.reducer;