import { Button } from '@mui/material';
import React, { useState } from 'react'
// ICONS
import { FaStar, FaTrash, FaWhatsapp, FaCheck,FaExclamationTriangle } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { IoIosResize, IoIosLink } from "react-icons/io";
import { IoShareSocialOutline } from "react-icons/io5";

// MUI
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';


// COMPONENTS
import Popup from '../Popup';
import { Alert, customURLEncode, dateConvert } from '../../Helpers/helpers';
import axios from 'axios';
import Swal from 'sweetalert2';
import './index.css'

// SHARE
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon
  } from 'react-share';

  
const PostCard = ({data, awardModal, style, className, own, getAdverts}) => {
    const fontColor = localStorage.theme == 'dark' ? 'white' : 'black';
    const [sharePop, setSharePop] = useState(false);

    const clearPhone = (p) => {
        if(p != null && p != undefined){
            p = String(p);
            var step = p.replace(/-/g, '');
            step = step.replace(/\+/g, '');
            return step;
        }
    }

    const Request = async (url, data, type="post") => {
        await axios.post(process.env.REACT_APP_API_URL + "/" + url, data, {
            headers: { "Authorization" : "Bearer " + localStorage.token }
        }).then((response) => {
            if(response.data?.status == "success"){
                Alert({
                    type: "success",
                    title: "Başarılı!",
                    text: response.data?.data
                });
            }else{
                Alert({
                    type: "error",
                    title: "Hata !",
                    text: response.data?.message
                });   
            }

        }).catch((error) => {
            Alert({
                type: "error",
                title: "Hata !",
                text: error.response.data?.message
            });
        })
    }

    const deleteAdvert = (data) => {
        Swal.fire({
            type: "info",
            title: 'Hata!',
            text: 'İlanı silmek istediğiniz emin misiniz ?',
            showCancelButton: true,
            cancelButtonText: "İptal",
            confirmButtonText: "Evet",
        }).then((respo) => {
            if(respo.isConfirmed){
                Request('delete-advert', {
                    "post_id" : data?.id,
                }).then((asd) => {
                    getAdverts();
                })
            }
        })
    }

    const foundAdvert = (data) => {
        const reason = document.getElementById('swal2-textarea');
        if(reason != null && reason != undefined){
            reason = reason.value;
        }
        return Swal.fire({
            type: "success",
            title: 'Yaşasın!',
            text: 'İlanınızı bulmanıza sevindik. Nasıl olduğunu bizimle paylaşmak ister misini?',
            input: "textarea",
            expectValue : true,
            showCancelButton: true,
            cancelButtonText: "İptal",
            confirmButtonText: 'Tamam',
            inputPlaceholder: "İsteğe bağlı",
            input: "textarea",
        }).then((response) => {
            if(response.isConfirmed){
                Request('found-advert', {
                    "post_id" : data?.id,
                    "reason" : response.value
                })
            }
        });
    }

    const complainAdvert = (data) => {
        const reason = document.getElementById('swal2-textarea');
        if(reason != null && reason != undefined){
            reason = reason.value;
        }
        return Swal.fire({
            type: "info",
            title: 'Şikayet!',
            text: 'Lütfen ilanı şikayet etme sebebinizi belirtiniz.',
            input: "textarea",
            expectValue : true,
            showCancelButton: true,
            theme: localStorage.theme,
            cancelButtonText: "İptal",
            confirmButtonText: 'Tamam',
            input: "textarea",
        }).then((response) => {
            if(response.isConfirmed){
                Request('complaint-advert', {
                    "post_id" : data?.id,
                    "reason" : response.value
                })
            }
        });
    }

  const openImage = (e) => {
    const popup = document.getElementById('popup');
    const popupImage = document.getElementById('popup-image');
    const closeBtn = document.getElementById('close');
    
    popup.style.display = 'flex';
    popupImage.src = e.target.src;

    closeBtn.addEventListener('click', () => {
      popup.style.display = 'none';
    });
  }

    function kopyala() {
        var copyText = window.location.hostname + '/ilan/' + data?.id + '-' + data?.slug;
        navigator.clipboard.writeText(copyText);
        setSharePop(false)
        Alert({
            type: "info",
            title : "Bağlantı Kopyalandı",
            text: " "
        });
      }
    const shareUrl = window.location.hostname + '/ilan/' + data?.id + '-' + data?.slug;
    const title = "Kayıp İlanı: " + data?.title;
    const exampleImage = data?.image

    return (
    <div className={'flex flex-col items-center gap-4 p-5 w-full md:w-1/2 xl:w-1/3 border rounded-md ' + className ?? ''} style={{ ...style, borderColor: fontColor }}>
        
        <Popup open={sharePop} Close={() => setSharePop(false)}>
            <div className='flex flex-row justify-center items-center gap-2'>
                <FacebookShareButton url={shareUrl} hashtag='kayipilan' image={exampleImage} quote={title} className="share-button">
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={title} className="share-button" hashtag={"kayipilan"}>
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={shareUrl} title={title} className='share-button' hashtag="kayipilan">
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <LinkedinShareButton title={title} url={shareUrl} className="share-button">
                    <LinkedinIcon size={32} round />
                </LinkedinShareButton>
                <button style={{ background: "transparent" }} onClick={() => kopyala()}>
                    <IoIosLink color={fontColor} size={19} />
                </button>
            </div>
        </Popup>

        <meta property="og:title" content={ data?.title } />
        <meta property="og:description" content={ data?.description } />
        <meta property="og:image" content={ data?.image } />
        <meta property="og:url" content={process.env.PUBLIC_URL + '/ilan/' + data?.id + '-' + data?.slug} />
        <meta property="og:type" content="website" />

        <div className='card-header flex items-start w-full justfiy-between'>
            <div className='postSahibi w-full flex flex-start items-start gap-1'>
                {/* <div onClick={ () => {window.location.replace("/user/" + data?.user?.id + "@" + customURLEncode(data?.user?.name))} }
                 className='ad-soyad cursor-pointer text text-md font-bold' style={{ color: fontColor }}>{ data?.user?.name }</div> */}
            </div>

            <div className='flex gap-2 items-center'>
                {
                    data?.is_award == true && data?.award != "#" ? <FaStar className='text-gold cursor-pointer' onClick={ () => {awardModal(data?.award)} } size={20} /> : <></>
                }
                {
                    own ? <>
                    <button className='bg-transparent border-none '
                    ><FaTrash className='text-red cursor-pointer' 
                    onClick={ () => {deleteAdvert(data)} } size={15} />
                    </button>

                    <button className='bg-transparent border-none '
                    ><FaCheck className='text-success cursor-pointer' 
                    onClick={ () => {foundAdvert(data)} } size={15} />
                    </button>
                    </> : <>
                    <button className='bg-transparent border-none '
                    ><FaExclamationTriangle className='text-danger cursor-pointer' 
                    onClick={ () => {complainAdvert(data)} } size={15} />
                    </button>
                    </>
                }
                
            </div>
        </div>

        <div className='card-image relative mt-2 flex items-center w-11/12 mx-auto'>
            <div className='hidden hover:block flex absolute text-center mx-auto bg-golge justify-center items-center h-full w-full'>
                 <IoIosResize color="white" size={29} />
            </div>
            <img src={ data?.image } onClick={(e) => openImage(e)}
            className='gallery-image cursor-pointer mx-auto'
            />
            
        </div>
        <div className='ad-soyad text text-xl font-bold' style={{ color: fontColor }}>{ data?.title }</div>

        <div className='bilgiler'>
            <ul className='flex items-center flex-wrap justify-between w-full gap-4 py-4'>
                {
                    data?.type != "item" ? <>
                    <li className='text text-sm' style={{ color: fontColor }}>Yaş: <span className='font-bold'>{ data?.age }</span></li>
                    <li className='text text-sm' style={{ color: fontColor }}>Cinsiyet: <span className='font-bold'>{ data?.gender }</span></li>
                    </> : <></>
            }
                <li className='text text-sm' style={{ color: fontColor }}>Şehir: <span className='font-bold'>{data?.city}/{data?.district}</span></li>
                <li className='text text-sm' style={{ color: fontColor }}>Kayıp Tarihi: <span className='font-bold'>{dateConvert(data?.lost_date)}</span></li>
            </ul>
        </div>


        <div className='aciklama text text-sm' style={{ color: fontColor }}>{ data?.description }</div>


        <div className='iletisim mt-2 flex items-center justify-between w-full bottom-0 mt-auto'>
            <Button key={1} href={'tel:' + data?.contact_number } target='_blank' variant="contained" color="primary">
                <FiPhoneCall />
            </Button>

            <Button key={1} variant='outlined' onClick={() => { setSharePop(true) }}>
                <IoShareSocialOutline color={fontColor} />
            </Button>
            
            <Button key={2} href={'https://wa.me/' + clearPhone(data?.contact_number ?? 0) } target='_blank' variant="contained" color="success">
                <FaWhatsapp />
            </Button>
        </div>
        
    </div>
  )
}
export default PostCard;