import React, { useEffect, useRef, useState } from 'react'
import PostCard from '../../Components/PostCard';
import { useDispatch, useSelector } from 'react-redux';
import { setAdverts } from '../../Redux/Homepage'
import axios from 'axios';
import Popup from '../../Components/Popup';
import { FaStar } from 'react-icons/fa';

const Homepage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const fontColor = localStorage.theme == 'dark' ? 'white' : 'black';
  const dispatch = useDispatch();
  const { Adverts } = useSelector((store) => store.home);


  const getAdverts = async () => {
    await axios.get( process.env.REACT_APP_API_URL +  '/adverts').then((response) => {
      let cevap = response.data;
      if(cevap.status == "success"){
        dispatch(setAdverts(cevap.data));
      }else{
        alert(cevap.message);
      }
    }).catch((error) => {
      if(error.response != undefined){
        alert(error.response.data.message);
      }else{
        alert(error)
      }

    })
  }


  useEffect(() => {
    getAdverts();
  },[])

  return (
    <div>
      <Popup open={openModal} Close={() => { setOpenModal(false) }}>
      <h1 className='flex items-center gap-2 text-lg font-bold text-gold pb-2'><FaStar className='text-gold cursor-pointer' /> Ödüllü</h1>
        <span className='text-sm font-bold' style={{color: fontColor}}>{ modalContent }</span>
        </Popup>
      <div className='posts py-4 flex flex-col gap-10 items-center bg-transparent overflow-auto'>
          {
            Adverts.length > 0 ?
              Adverts.map(
                item => <PostCard data={item} awardModal={(award) => {setOpenModal(true); setModalContent(award) }} />
                )
              :
              <h3 className='text-xl flex flex-col justify-center items-center absolute font-bold text-center text-black dark:text-white'
              >İlan bulunamadı.</h3>
          }
          
        </div>
    </div>
  )
}
export default Homepage;