import { configureStore } from '@reduxjs/toolkit'
import Homepage from './Redux/Homepage'
import MeSlice from './Redux/MeSlice'
import UserSlice from './Redux/UserSlice'
import PostDetailSlice from './Redux/PostDetailSlice'


const store = configureStore({
    reducer:{
        "home" : Homepage,
        "me" : MeSlice,
        "user" : UserSlice,
        "post_detail" : PostDetailSlice
    }

})

export default store;