import PostCard from '../../Components/PostCard';
import { useDispatch, useSelector } from 'react-redux';
import { setAdverts, setUser } from '../../Redux/UserSlice'
import axios from 'axios';
import Popup from '../../Components/Popup';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { FaStar } from 'react-icons/fa';
import { Alert, checkAuth }  from '../../Helpers/helpers';
import { register } from '../../Validataions/Register';
import { useParams } from 'react-router-dom';
import { SettingsInputCompositeRounded } from '@mui/icons-material';
import Swal from 'sweetalert2';


const User = () => {
  const { param } = useParams();
  const [ registerModal, setRegisterModal ] = useState(false);
  const [ activeModal, setActiveModal ] = useState(false);
  const fontColor = localStorage.theme == 'dark' ? 'white' : 'black';
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const dispatch = useDispatch();
    const { User, Adverts } = useSelector((store) => store.user);

    const registerForm = useFormik({
      initialValues: {
          name: User?.name,
          email: User?.email,
          password: '',
          password_confirmation: '',
      },
      validationSchema: register,
      onSubmit: async values => {
          axios.post( process.env.REACT_APP_API_URL + "/update", values )
          .then((response) => {
              let cevap = response.data;
              if(cevap.status == "success"){
                  localStorage.email = cevap.data.email
                  setRegisterModal(false);
                  setActiveModal(true);
                  //window.location.reload();
              }else{
                  Alert({type:"error", title:"Hata!", text:cevap.message});
              }
          }).catch((error) => {
              Alert({type:"error", title:"Hata!", text:error.response.message});
          });
      },
      onChange: values => {
        
      }
  });  

  const getAdverts = async () => {
    await axios.get( process.env.REACT_APP_API_URL +  '/users/' + param).then((response) => {
      let cevap = response.data;
      if(cevap.status == "success"){
        dispatch(setAdverts(cevap.data.adverts));
        dispatch(setUser(cevap.data.user));
      }else{
        alert(cevap.message);
      }
    }).catch((error) => {
      if(error.response != undefined){
        alert(error.response.message);
      }

    })
  }
  const Request = async (url, data, type="post") => {
    await axios.post(process.env.REACT_APP_API_URL + "/" + url, data, {
        headers: { "Authorization" : "Bearer " + localStorage.token }
    }).then((response) => {
        if(response.data.status == "success"){
            Alert({
                type: "success",
                title: "Başarılı!",
                text: response.data.data
            });
        }else{
            Alert({
                type: "error",
                title: "Hata !",
                text: response.data.message
            });   
        }

    }).catch((error) => {
        Alert({
            type: "error",
            title: "Hata !",
            text: error.response.data.message
        });
    })
}

  const complaintUser = (data) => {
    const reason = document.getElementById('swal2-textarea');
    if(reason != null && reason != undefined){
        reason = reason.value;
    }
    return Swal.fire({
        type: "info",
        title: 'Şikayet!',
        text: 'Lütfen ilanı şikayet etme sebebinizi belirtiniz.',
        input: "textarea",
        expectValue : true,
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: 'Tamam',
        input: "textarea",
    }).then((response) => {
        if(response.isConfirmed){
            Request('complaint-user', {
                "user_id" : data.id,
                "reason" : response.value
            })
        }
    });
}



  useEffect(() => {
    checkAuth();
    getAdverts();
  },[])

  return (
    <div className='flex p-10 flex-col gap-2'>
          <Popup key={2} open={registerModal} Close={() => { setRegisterModal(false) }}>
            <form className='flex flex-col gap-4 items-center justify-center' autoComplete='off' onSubmit={registerForm.handleSubmit}>
              <div className='form-group'>
                  <input
                      className='p-2 border rounded-xl'
                      style={{ borderColor: fontColor }}
                      id="name"
                      name="name"
                      placeholder='Ad Soyad'
                      autoComplete='off'
                      type="text"
                      onChange={registerForm.handleChange}
                      value={registerForm.values.name}
                  />
                    { registerForm.errors.name && <div className='text text-red'>{ registerForm.errors.name } </div>}
              </div>
              <div className='form-groyp'>
                  <input
                      className='p-2 border rounded-xl'
                      style={{ borderColor: fontColor }}
                      id="email"
                      name="email"
                      placeholder='Email'
                      autoComplete='off'
                      type="email"
                      onChange={registerForm.handleChange}
                      value={registerForm.values.email}
                  />
                      { registerForm.errors.email && <div className='text text-red'>{ registerForm.errors.email } </div>}
              </div>
              <div className='form-groyp'>
                  <input
                      className='p-2 border rounded-xl'
                      id="password"
                      style={{ borderColor: fontColor }}
                      name="password"
                      type="password"
                      placeholder='Şifre'
                      autoComplete='off'
                      onChange={registerForm.handleChange}
                      value={registerForm.values.password}
                  />
                      { registerForm.errors.password && <div className='text text-red'>{ registerForm.errors.password } </div>}
              </div>
              <div className='form-groyp'>
                  <input
                      className='p-2 border rounded-xl'
                      id="password_confirmation"
                      style={{ borderColor: fontColor }}
                      name="password_confirmation"
                      type="password"
                      placeholder='Şifre Tekrar'
                      autoComplete='off'
                      onChange={registerForm.handleChange}
                      value={registerForm.values.password_confirmation}
                  />
                    { registerForm.errors.password_confirmation && <div className='text text-red'>{ registerForm.errors.password_confirmation } </div>}
              </div>
              <button type="submit">Kayıt ol</button>
            </form>
        </Popup>

        <div className='flex flex-row gap-2 user-information justify-between'>
          <span className='text text-md font-bold' style={{ color:fontColor }}>{ User?.name }</span>

          <span onClick={() => { complaintUser(User) }} className='text cursor-pointer underline text-md font-bold' style={{ color:"red" }}>Şikayet et</span>
        </div>

        <div className='ilanlar mt-2'>
            <h1 className='informations text font-bold text-2xl text-center' style={{ color:fontColor }}>İlanlar ( {Adverts?.length ?? 0} )</h1>
            <Popup open={openModal} Close={() => { setOpenModal(false) }}>
                <h1 className='flex items-center gap-2 text-lg font-bold text-gold pb-2'
                ><FaStar className='text-gold cursor-pointer' /> Ödüllü</h1>
                    { modalContent }
            </Popup>
            <div className='posts mt-4 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 mx-auto justify-center gap-4 w-full'>
            {
            Adverts.map(
            item => <PostCard getAdverts={() => { getAdverts() }}
              className={"col-span-6 md:col-span-3 lg:col-span-2 w-full"} style={{ width:"100%" }}
            own={true} data={item} awardModal={(award) => {setOpenModal(true); setModalContent(award) }}
             />
            )}
            </div>
        </div>

        

    </div>
  )
}

export default User