import React from 'react'
import { useFormik } from 'formik';

export const Register = () => {
    const formik = useFormik({
        initialValues: {
            username: '',
            email: '',
            password: '',
        },
        onSubmit: values => {
            alert(JSON.stringify(values, null, 2));
        },
    });
  return (
    <div className='register-card w-full mt-10 flex items-center justify-center mx-auto '>
        <div className='cardo w-1/3 bg-card dark:bg-dark-card '>
            <form className='flex flex-col gap-4 items-center justify-center' onSubmit={formik.handleSubmit}>
                <div className='form-groyp'>
                    <label htmlFor="username">Username</label>
                    <input
                        id="username"
                        name="username"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.username}
                    />
                </div>
                <div className='form-groyp'>
                    <label htmlFor="email">Email</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                </div>
                <div className='form-groyp'>
                    <label htmlFor="password">Password</label>
                    <input
                        id="password"
                        name="password"
                        type="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                    />
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
    </div>
  )
}
