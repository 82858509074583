import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    User:{},
    Adverts:[]
}

const UserSlice = createSlice({
    name: "user",
    initialState,
    reducers:{
        setAdverts(state,action){
            state.Adverts = action.payload;
        },
        setUser(state,action){
            state.User = action.payload;
        }
    }
})

export const { setAdverts,setUser } = UserSlice.actions
export default UserSlice.reducer;