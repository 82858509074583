import React from 'react'

const Kvkk = () => {
  return (
    <div style={{ color: localStorage.theme == 'dark' ? 'white' : 'black' }}>
<div
  style={{
    color: "#d4d4d4",
    backgroundColor: "#1e1e1e",
    fontFamily: 'Menlo, Monaco, "Courier New", monospace',
    fontWeight: "normal",
    fontSize: 12,
    lineHeight: 2,
    whiteSpace: "pre"
  }}
>
  <span style={{ color: "#d4d4d4" }}>
    <strong>
      KAYIPİLANI.COM KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ POLİTİKASI
    </strong>
  </span>
  <strong>
    <br />
  </strong>
  <span style={{ color: "#d4d4d4" }}>1. Veri Sorumlusu ve Temsilcisi</span>
  <br />
  <span style={{ color: "#d4d4d4" }}>
    6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) kapsamında, kişisel
    verileriniz veri sorumlusu olarak [Şirket Adı] tarafından aşağıda açıklanan
    kapsamda işlenebilecektir.
  </span>
  <br />
  <span style={{ color: "#d4d4d4" }}>2. Kişisel Verilerin İşlenme Amacı</span>
  <br />
  <span style={{ color: "#d4d4d4" }}>
    Toplanan kişisel verileriniz, hizmetlerimizi sunmak, kullanıcı memnuniyetini
    sağlamak, hukuki yükümlülüklerimizi yerine getirmek ve sizlere daha iyi bir
    hizmet sunabilmek amacıyla işlenmektedir.
  </span>
  <br />
  <span style={{ color: "#d4d4d4" }}>3. İşlenen Kişisel Verileriniz</span>
  <br />
  <span style={{ color: "#d4d4d4" }}>
    Hizmetlerimiz kapsamında, ad, soyad, e-posta adresi, telefon numarası gibi
    kişisel verileriniz işlenmektedir.
  </span>
  <br />
  <span style={{ color: "#d4d4d4" }}>
    4. Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi
  </span>
  <br />
  <span style={{ color: "#d4d4d4" }}>
    Kişisel verileriniz, kayipilani.com web sitesi üzerinden doldurmuş olduğunuz
    formlar, yaptığınız başvurular ve aldığınız hizmetler aracılığıyla
    toplanmaktadır. Bu veriler, KVKK’nın 5. ve 6. maddelerinde belirtilen hukuki
    sebeplere dayalı olarak işlenmektedir.
  </span>
  <br />
  <span style={{ color: "#d4d4d4" }}>
    5. Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği
  </span>
  <br />
  <span style={{ color: "#d4d4d4" }}>
    Toplanan kişisel verileriniz, yasal yükümlülükler çerçevesinde ve yukarıda
    belirtilen amaçlar doğrultusunda, kanunen yetkili kamu kurumları ile özel
    kişilere aktarılabilecektir.
  </span>
  <br />
  <span style={{ color: "#d4d4d4" }}>6. Kişisel Verilerin Saklanma Süresi</span>
  <br />
  <span style={{ color: "#d4d4d4" }}>
    Kişisel verileriniz, işlenme amaçlarının gerektirdiği süre boyunca
    saklanacak olup, yasal saklama süresi sona erdiğinde, periyodik imha
    işlemleri kapsamında silinecek, yok edilecek veya anonim hale
    getirilecektir.
  </span>
  <br />
  <span style={{ color: "#d4d4d4" }}>7. Veri Sahibinin Hakları</span>
  <br />
  <span style={{ color: "#d4d4d4" }}>
    KVKK’nın 11. maddesi uyarınca, veri sahipleri;
  </span>
  <br />
  <span style={{ color: "#d4d4d4" }}>
    Kişisel veri işlenip işlenmediğini öğrenme,
  </span>
  <span style={{ color: "#d4d4d4" }}>
    Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
  </span>
  <span style={{ color: "#d4d4d4" }}>
    Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp
    kullanılmadığını öğrenme,
  </span>
  <span style={{ color: "#d4d4d4" }}>
    Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri
    bilme,
  </span>
  <span style={{ color: "#d4d4d4" }}>
    Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
    düzeltilmesini isteme,
  </span>
  <span style={{ color: "#d4d4d4" }}>
    KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde kişisel verilerin
    silinmesini veya yok edilmesini isteme,
  </span>
  <span style={{ color: "#d4d4d4" }}>
    İşlemlerin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
    isteme,
  </span>
  <span style={{ color: "#d4d4d4" }}>
    İşlenen verilerin münhasıran otomatik sistemler aracılığıyla analiz edilmesi
    suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,
  </span>
  <span style={{ color: "#d4d4d4" }}>
    Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması
    hâlinde zararın giderilmesini talep etme
  </span>
  <span style={{ color: "#d4d4d4" }}>haklarına sahiptir.</span>
  <br />
  <span style={{ color: "#d4d4d4" }}>8. İletişim Bilgileri</span>
  <br />
  <span style={{ color: "#d4d4d4" }}>
    Kişisel verilerinizle ilgili her türlü soru ve talepleriniz için bizimle
    iletişime geçebilirsiniz.
  </span>
  <br />
  <span style={{ color: "#d4d4d4" }}>Adres: Kayıp İlan</span>
  <span style={{ color: "#d4d4d4" }}>E-posta: m.ali.damar2024@gmail.com</span><br />
  <span style={{ color: "#d4d4d4" }}>Telefon: +90541 842 5693</span>
</div>

</div>
  )
}

export default Kvkk
